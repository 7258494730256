import React from "react"
import "./InputStyle.scss"

export const Input = props => {
  return (
    <div className="input-wrapper">
      <input
        type={props.type}
        onChange={input => props.onChange(input.target.value)}
        value={props.value}
        placeholder={props.placeholder}
        required={props.required}
      />
    </div>
  )
}

export const Textarea = props => {
  return (
    <div
      className={
        props.className
          ? ["textarea-wrapper", props.className].join(" ")
          : "textarea-wrapper"
      }
    >
      <textarea
        value={props.value}
        placeholder={props.placeholder}
        className="multilineInputStyle"
        required={props.required}
        onChange={input => props.onChange(input.target.value)}
      />
    </div>
  )
}

export const Button = props => {
  return (
    <div
      className={
        props.className
          ? ["button-wrapper", props.className].join(" ")
          : "button-wrapper"
      }
    >
      <button className="button-click" onClick={props.onClick}>
        {props.text}
      </button>
    </div>
  )
}

export const DropDown = props => {
  return (
    <div
      className={
        props.className
          ? ["dropdown-wrapper", props.className].join(" ")
          : "dropdown-wrapper"
      }
    >
      {/* eslint-disable-next-line */}
      <select onChange={props.onChange} placeholder={props.placeholder}>
        {props.children}
      </select>
    </div>
  )
}
