import React from "react"
import "./BackButton.scss"
import { navigate } from "gatsby"

const BackButton = props => {
  return (
    <>
      {/* eslint-disable-next-line */}
      <div
        className="back-button-wrapper"
        onClick={() => {
          navigate(props.url)
        }}
        style={props.isBottom ? { marginTop: "20px" } : {}}
      >
        <div>
          <i className="back-arrow" />
        </div>
        <div>
          <p>Zurück</p>
        </div>
      </div>
    </>
  )
}

export default BackButton
