import React, { useEffect } from "react"
import "./LayoutStyle.scss"
import { unloadRecaptcha } from "../../utils/recaptcha"
import Menu from "../Menu/Menu"
import { MobileFooter } from "../Footer/Footer"
import BackButton from "../BackButton/BackButton"
import PWAPrompt from "../PWAPrompt/PWAPrompt"

const Layout = props => {
  useEffect(() => {
    unloadRecaptcha()
  }, [])

  return (
    <>
      <div className="layout-wrapper" id="top">
        <PWAPrompt />
        <Menu />
        <div className="content-wrapper">
          <BackButton url={props.url ? props.url : "/"} />
          {props.children}
          <BackButton isBottom={true} url={props.url ? props.url : "/"} />
        </div>
        <MobileFooter />
      </div>
    </>
  )
}

export default Layout
