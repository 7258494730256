import React from "react"
import "./RecipeSelectionStyle.scss"
import { Button, DropDown } from "../Input/Input"
import { navigate } from "gatsby"

const RecipeSelection = props => {
  return (
    <div className="recipe-selection-wrapper">
      {props.recipes.length > 0 ? (
        <DropDown
          placeholder="Rezeptauswahl"
          onChange={event => {
            if (navigate(event.target.value) !== "title") {
              navigate(event.target.value)
            }
          }}
        >
          <option value="title">Bitte auswählen</option>
          {props.recipes.map(element => {
            return (
              <option key={element.name} value={element.url}>
                {element.name}
              </option>
            )
          })}
        </DropDown>
      ) : (
        <p className="no-recipes-found">Keine Rezepte vorhanden</p>
      )}
      {props.video && (
        <Button
          text={"Wie macht man " + props.mainProduct}
          onClick={() => {
            navigate("/" + props.url + "/video")
          }}
        />
      )}
    </div>
  )
}

export default RecipeSelection
