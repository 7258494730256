import React, { useState, useEffect } from "react"
import "./MenuStyle.scss"
import { Link } from "gatsby"
import Logo from "../../assets/images/logo.png"
import Bars from "../../assets/icons/menu.svg"
import Close from "../../assets/icons/close.svg"

const Menu = () => {
  const [isOpen, setIsOpen] = useState(false)
  const mKeyCode = 77

  useEffect(() => {
    const keyHandler = event => {
      switch (event.keyCode) {
        // KeyCode for "m"
        // Toggle Menu in mobile with key
        case mKeyCode:
          if (
            // Nur wenn es auch sichtbar ist toogeln
            window.getComputedStyle(document.getElementById("mobil-menu"))
              .display === "block"
          ) {
            setIsOpen(isOpen => !isOpen)
          }
          break

        default:
          break
      }
    }

    document.addEventListener("keydown", keyHandler, false)

    return () => {
      document.removeEventListener("keydown", keyHandler, false)
    }
  }, [])

  return (
    <div className="menu-wrapper">
      <Link to="/">
        <img src={Logo} alt="Rolf Schick GmbH" />
      </Link>

      <div className="mobil-menu" id="mobil-menu">
        {/* eslint-disable-next-line */}
        <div className="menu-icon-wrapper" onClick={() => setIsOpen(!isOpen)}>
          <Bars />
        </div>

        <div
          className="mobile-menu-overlay"
          style={isOpen ? { display: "block" } : { display: "none" }}
        >
          {/* eslint-disable-next-line */}
          <div className="close-button" onClick={() => setIsOpen(!isOpen)}>
            <Close />
          </div>

          <div className="menu-items-wrapper">
            <ul>
              <li>
                <Link to="/" onClick={() => setIsOpen(!isOpen)}>
                  Startseite
                </Link>
              </li>
              <li>
                <Link to="/#section_1" onClick={() => setIsOpen(!isOpen)}>
                  Kontakt
                </Link>
              </li>
              <li>
                <Link to="/#section_2" onClick={() => setIsOpen(!isOpen)}>
                  Wie Sie uns finden
                </Link>
              </li>
              <li>
                <Link to="/privacy" onClick={() => setIsOpen(!isOpen)}>
                  Datenschutz
                </Link>
              </li>
              <li>
                <Link to="/impressum" onClick={() => setIsOpen(!isOpen)}>
                  Impressum
                </Link>
              </li>
            </ul>
            <Link to="/">
              <img src={Logo} alt="Rolf Schick GmbH" />
            </Link>
          </div>
        </div>
      </div>

      <div className="desktop-menu">
        <ul>
          <li>
            <Link to="/">Startseite</Link>
          </li>
          <li>
            <Link to="/#section_1">Kontakt</Link>
          </li>
          <li>
            <Link to="/#section_2">Wie Sie uns finden</Link>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default Menu
