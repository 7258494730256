import React, { useEffect } from "react"
import SEO from "../components/SEO"
import Layout from "../components/Layout/Layout"
import { graphql } from "gatsby"
import ProductDescription from "../components/ProductDescription/ProductDescription"
import RecipeSelection from "../components/RecipeSelection/RecipeSelection"
import "isomorphic-fetch"

const ProductTemplate = ({ data }) => {
  const productData = data.allSitePage.edges[0].node.context

  useEffect(() => {
    fetch("/php/counter.php?id=" + productData.url, { method: "GET" }).catch(
      error => {
        console.error("counter", error)
      }
    )
  }, [])

  return (
    <>
      <SEO title={productData.name} />

      <Layout>
        <div className="product-template-wrapper">
          <ProductDescription
            url={productData.url}
            description={productData.description}
            name={productData.name}
            saisonalname={productData.saison}
          />
          <RecipeSelection
            url={productData.url}
            video={productData.video}
            name={productData.name}
            mainProduct={productData.doIt}
            recipes={productData.recipe}
          />
        </div>
      </Layout>
    </>
  )
}

export default ProductTemplate

export const query = graphql`
  query($path: String) {
    allSitePage(filter: { path: { eq: $path } }) {
      edges {
        node {
          context {
            description
            name
            url
            doIt
            saison
            video
            recipe {
              name
              url
              main
            }
          }
        }
      }
    }
  }
`
