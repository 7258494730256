import React from "react"
import "./ProductDescriptionStyle.scss"
import Label_Blaetterteig from "../../assets/images/products/Label_Blaetterteig.png"
import Label_Dinkelteig from "../../assets/images/products/Label_Dinkelteig.png"
import Label_Fruehlingsrollenteig from "../../assets/images/products/Label_Fruehlingsrollenteig.png"
import Label_Maultaschenteig from "../../assets/images/products/Label_Maultaschenteig.png"
import Label_Muerbteig from "../../assets/images/products/Label_Muerbteig.png"
import Label_Strudelteig from "../../assets/images/products/Label_Strudelteig.png"
import Logo from "../../assets/images/logo.png"

const ProductDescription = props => {
  const getProductImage = url => {
    switch (url) {
      case "maultaschenteig":
        return Label_Maultaschenteig
      case "strudelteig":
        return Label_Strudelteig
      case "dinkelteig":
        return Label_Dinkelteig
      case "fruehlingsrollenteig":
        return Label_Fruehlingsrollenteig
      case "muerbteig":
        return Label_Muerbteig
      case "blaetterteig":
        return Label_Blaetterteig
      default:
        return Logo
    }
  }

  return (
    <>
      <div className="product-description-wrapper">
        <h1>{props.name}</h1>
        <div className="description-flex-wrapper">
          <div>
            <img src={getProductImage(props.url)} alt={props.name} />
          </div>
          <div>
            <p>{props.description}</p>
          </div>
        </div>
      </div>
      {props.saisonalname && (
        <div className="new-recipe-headline">
          <h2>
            Jetzt Saisonal probieren:
            <br />
            {props.saisonalname}
          </h2>
          <div className="new-badge">NEU</div>
        </div>
      )}
    </>
  )
}

export default ProductDescription
